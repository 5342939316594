/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import TopBanglesDescription from "views/InshaBanglesPage/Section/TopBanglesDescription";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function ReturnPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
        <Helmet>
        <title>Top 5 Bangles Manufactures</title>
        <meta
          name="description"
          content="Bangles Manufacturers in India. We also provide Customized Packaging Facility besides using good quality plastic and carton for securely packaging the bangles and kadas."
        />
        <meta name="keywords" content="Top 5 Bangles Manufacture, Top 5 Bangles Manufacture in Malad, Top 5 Bangles Manufacture in Kandivali, Top 5 Bangles Manufacture in Mumbai, Top 5 Bangles Manufacture in Maharastra, Top 5 Bangles Manufacture in India, Top 5 Bangles Expoter in Mumbai, Top 5 Bangles Expoter in Maharastra, Top 5 Bangles Expoter in India" />
      </Helmet>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <TopBanglesDescription />
          <br />
        </div>
      </div>
    </div>
  );
}