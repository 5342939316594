import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { TiArrowForward } from "react-icons/ti";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import logo from "assets/img/examples/apple-insha-bangles-icon.png";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(aboutUsStyle);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function InshaProductList() {
  const [prodList, setProdList] = React.useState([]);
  const [page, setpage] = React.useState(0);
  const [loginModal, setLoginModal] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classPop = useStylPop();

  const activeSession = sessionStorage.getItem("sessionData");

  const fetchData = async () => {
    // console.log(page + 1);
    setpage(page + 1);
    axios
      .get(
        localApi +
        `/product/list/byLimitActive?adminId=2&active=1&page=${page}&size=4`
      )
      .then((res) => setProdList([...prodList, ...res.data]));
  };

  useEffect(() => {
    fetchData();
    setTimeout(hideAlertPop, 2000);
    setLoginModal(true);
  }, []);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Product List</title>
        <meta
          name="description"
          content="We make bangles with good quality raw material at factory prices.Bangles such as Gold Polished bangles, Die gold plated bangles, silver bangles, Micro Gold plated, rose gold plated bangles, 3tone bangles, Diamond Dell bangles, Rhodium bangles.We are basically working on customize designs and customise orders.you can get any sizes,colors, models and designs,we can make the bangles according to your requirements and needs. We are providing Zero making charges on bulk quantity orders (minimum purchase of 10 cartons)., The best gold plated bangles is a true staple. Here are 30 of our favourite bangles, Earrings, Finger Rings, Bracelets Punjabi kada Watch.,"
        />
        <meta
          name="keywords"
          content="fancy bangles, party wear bangles for women, stylish gold plated bangles, trendy gold bangles, imitation bangles, designer bangles, brass gold plated bangles, casual everyday bangles, elegant and beautiful bangles, finely polished, unique design angles,charming bangles, catchy designs, 
Golwire Small, Golwire Medium, 1MM, 1.5MM, 2MM, 2.5MM, 3MM, 3.5MM, 4MM, 4.5MM, 5MM,5.2MM, 5/3MM, 5.5MM, 6MM, 6.5MM, 7MM, 7.2MM, 7/3MM, 7.8MM, 8MM, 8/3MM, 9MM, 9.2MM, 9/3MM, 10MM, 11MM, 11/3MM, 11/4MM, 11/5MM, 12MM, 12/3MM, 15MM, 19MM, 20MM, 20/3MM, 25MM, 
Imitation bangles,
Gold plated bangles, Gold Plated & Meena Bangles, Gold plated Mens kada, Gold plated bangles in Saudi Arabia are very unique and beautifully designed, gold dolphin bangle, silver dolphin bangle, gold plated bangles in Maharashtra, mumbai, Goregaon East, malad west, kandivali West,
Silver plated bangles, Mens Silver plated Punjabi kada,
Rhodium plated bangles,
2 tone bangles & bracelets, Rhodium & Gold plated, Silver & Gold plated,
3 tone bangles & bracelets set, Tri color bangles & bracelets set, 7 PCS semanario, Micro gold plated bangles & bracelets set, Rose gold plated bangles & bracelets set, Semanario bangles bracelets, Tri color lucky elephant bangle & bracelets, 3Tone Tri-Color Oro Laminado Bangle & Bracelets 6MM & 7MM set, Exporter of Three Tone Bangles set - Plane Three Tone Bangles set, 
Pola bangles, Green color pola, Red color pola, Maroon color pola, White color pola, Maharashtrian Green Pola Bangles, Gold Plated Acrylic Odisha Pola Bangles, Fancy Maroon Gold Plated Pola Bangles, Gold Plated Bengal Acrylic Pola Banglese, Red and White Gold Plated Pola Bangles,
Manufacturer of 3 Tone Bangles,
Engraving & etching 6MM/7MM/8MM/10MM/12MM/20mm/25MM 3Tone Guadalupe/Elephant/Dolphin bangle & bracelet,
etc."
        />
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
      />
      <Parallax
        // image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.pageHeader}
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>Product List</h1>
              <h4 style={{ color: "white" }}>
                Insha{"'"}s products are light-weight , elegant and
                sophisticated - the perfect fit for today{"'"}s modern women
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <Dialog
            style={{ marginTop: "10%" }}
            classes={{
              root: classPop.modalRoot,
              paper: classPop.modal + " " + classPop.modalLogin,
            }}
            open={loginModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setLoginModal(false)}
            aria-labelledby="login-modal-slide-title"
            aria-describedby="login-modal-slide-description"
          >
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <h5>Loading...</h5>
              <DialogContent
                id="login-modal-slide-description"
                className={classPop.modalBody}
              >
                <GridContainer justify="center">
                  <Spinner
                    onChange={() => hideAlertPop()}
                    style={{ color: "black" }}
                  />
                  {/* <Spinner onChange={() => hideAlertPop()} style={{ color: "#00acc1" }} />
                    <Spinner onChange={() => hideAlertPop()} style={{ color: "#f44336" }} />
                    <Spinner onChange={() => hideAlertPop()} style={{ color: "#ff9800" }} />
                    <Spinner onChange={() => hideAlertPop()} style={{ color: "#9c27b0" }} /> */}
                </GridContainer>
                <br />
              </DialogContent>
            </DialogTitle>
          </Dialog>
          <div className={classes.relatedProducts}>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              You may also be interested in:
            </h3>
            <InfiniteScroll
              dataLength={prodList.length}
              next={fetchData}
              hasMore={true}
            >
              <div className={classes.container}>
                <GridContainer>
                  {prodList &&
                    prodList.map((prod) => (
                      <GridItem sm={6} md={3} key={prod.id}>
                        <Card product>
                          <CardHeader image>
                            {/* <CornerRibbon
                          position={prod.total > 0 ? "top-left" : "top-right"} // position="top-right" // OPTIONAL, default as "top-right"
                          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                          backgroundColor="#00acc1" // OPTIONAL, default as "#2c7"
                          containerStyle={{}} // OPTIONAL, style of the ribbon
                          style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                          className="" // OPTIONAL, css class of ribbon
                        >
                          {" "}
                          {prod.total > 0 ? "In Stock" : "Make To Order"}
                        </CornerRibbon> */}
                            <a
                              href={`/insha-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                            // &Plating=${prod.plating.description.slice(8)}
                            >
                              {/* <img src={cardProduct1} alt="cardProduct" /> */}
                              {/* {prod.design1.img1active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design1.image1}
                              alt="..."
                            />
                          ) : prod.design2.img2active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design2.image2}
                              alt="..."
                            />
                          ) : prod.design3.img3active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design3.image3}
                              alt="..."
                            />
                          ) : prod.design4.img4active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design4.image4}
                              alt="..."
                            />
                          ) : prod.design5.img5active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design5.image5}
                              alt="..."
                            />
                          ) : prod.design6.img6active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design6.image6}
                              alt="..."
                            />
                          ) : (
                            ""
                          )} */}
                              <img
                                src={
                                  prod.productImage[0].cropImageUrl +
                                  "?id=" +
                                  prod.productImage[0].imgId
                                }
                                alt="..."
                              />
                              <p
                                style={{
                                  position: "absolute",
                                  top: "65%",
                                  left: "50%",
                                  width: "65px",
                                  height: "65px",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                <img src={logo} alt="..." />
                                {/* {imgApi + "" + prod.design1.image1 !==
                            imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design2.image2 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design3.image3 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design4.image4 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design5.image5 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design6.image6 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : (
                              ""
                            )} */}
                              </p>
                            </a>
                          </CardHeader>
                          <CardBody>
                            <h6
                              className={classNames(
                                classes.cardCategory,
                                classes.textRose
                              )}
                            >
                              {prod.productCategory.title +
                                " " +
                                prod.productParent.title}{" "}
                              <br /> {prod.itemCodeNumber}-{prod.id}
                            </h6>
                            <h4 className={classes.cardTitle}>
                              {prod.prodDescription}
                            </h4>
                            <div className={classes.cardDescription}>
                              {(prod.readyGram * prod.productSets.pcs).toFixed(
                                4
                              )}{" "}
                              Avg Gram Of {prod.productSets.setDesc} {"and "}
                              {prod.productBox.boxDesc}
                              {","} {prod.cartDescription}
                              {"."}
                            </div>
                          </CardBody>
                          <CardFooter className={classes.justifyContentBetween}>
                            <div className={classes.price}>
                              {prod.gstCash === true ? (
                                <>
                                  <h5>
                                    <span style={{ color: "red" }}>
                                      Price ₹ {(prod.taxInclude / prod.productBox.box).toFixed(2) + " Inc."}
                                    </span>
                                  </h5>
                                </>
                              ) : (
                                <>
                                  <h5>
                                    <span style={{ color: "black" }}>
                                      Price ₹ {(prod.cashPrice / prod.productBox.box).toFixed(2)}
                                    </span>
                                  </h5>
                                </>
                              )}
                              {/* {activeSession ? "" : prod.taxCalculation.profitactive === 1 ? <h5>Price <span style={{ color: "red" }}>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> : <h5>Price <span>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-*</span></h5>}

                                                {!activeSession ? <h6><a href="/bangles-login">Login</a> to see prices and exciting offers.
                                                </h6> : prod.taxCalculation.profitactive === 1 ? <h6>Price <span style={{ textDecoration: "line-through", color: "red" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span>{" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> :
                                                    <h6>Price <span style={{ textDecoration: "line-through" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span> {" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6>
                                                } */}

                              {/* {!activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              prod.taxCalculation.cash_gst === 1 ? (
                                <>
                                  <h5>
                                    <span style={{ color: "red" }}>
                                      Price ₹{" "}
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /-
                                    </span>
                                  </h5>{" "}
                                  <h6>
                                    <a href="/bangles-login">Login</a> to see
                                    prices and exciting offers.
                                  </h6>{" "}
                                </>
                              ) : (
                                <h5>
                                  Price{" "}
                                  <span>
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </span>
                                </h5>
                              )
                            ) : prod.taxCalculation.cash_gst === 1 ? (
                              <>
                                <h5>
                                  <span style={{ color: "#9c27b0" }}>
                                    Price ₹{" "}
                                    {(
                                      prod.taxCalculation.taxincl / prod.box.box
                                    ).toFixed(2)}
                                    /-
                                  </span>
                                </h5>{" "}
                                <h6>
                                  <a href="/bangles-login">Login</a> to see
                                  prices and exciting offers.
                                </h6>
                              </>
                            ) : (
                              <h5>
                                Price{" "}
                                <span>
                                  ₹{" "}
                                  {(
                                    prod.taxCalculation.cash / prod.box.box
                                  ).toFixed(2)}
                                  /-*
                                </span>
                              </h5>
                            )
                          ) : (
                            ""
                          )}

                          {activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "red",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            ) : (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#9c27b0",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            )
                          ) : (
                            ""
                          )} */}
                            </div>
                            <div className={classes.stats}>
                              <Tooltip
                                id="tooltip-top"
                                title="Save to Wishlist"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button justIcon color="rose" simple>
                                  <Favorite />
                                </Button>
                              </Tooltip>
                            </div>
                          </CardFooter>
                          <div style={{ textAlign: "center", marginBottom: "7px" }}>
                            <Button
                              color="rose"
                              href={`/insha-product?id=${prod.id}&catdesc=${prod.productCategory.title
                                }&cat1desc=${prod.productParent.title
                                }`}
                            >
                              Buy Now
                            </Button>
                          </div>
                        </Card>
                      </GridItem>
                    ))}
                </GridContainer>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
