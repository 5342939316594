/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { headApi } from "utils/headApi";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import InshaSection from "views/InshaBanglesPage/Section/InshaSectionPro.js";
import InshaContact from "views/InshaBanglesPage/Section/InshaContact.js";
import Icon from "@material-ui/core/Icon"; 
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { FiPhoneCall } from "react-icons/fi";
import Close from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import InshaTeam from "views/InshaBanglesPage/Section/InshaTeam";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";
// import ReactGA from "react-ga";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";


const useStyles = makeStyles(presentationStyle);
const useStyle = makeStyles(javascriptStyles);
const useStyl = makeStyles(productStyle);

// function initializeAnalytics(){
//   ReactGA.initialize("G-N88RPJG9X5")
//   ReactGA.pageview("/home")
// }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function InshaPage() {
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [otpNumber, setOtpNumber] = React.useState("");
  const [otpNumberErr, setOtpNumberErr] = React.useState("");
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [loginModal, setLoginModal] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const classPop = useStyle();
  const classe = useStyles();
  const history = useHistory();

  const activeSession = sessionStorage.getItem("sessionData");

  // useEffect(() => {
  //   fetch(`http://localhost:8085/api/typeList`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "content-type": "application/json",
  //         accept: "application/json",
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       console.log(response);
  //     })
  // }, []);

  const handleSendOtp = () => {
    let errorCount = 0;
    if (!mobile || mobile.length < 12) {
      errorCount++;
      setMobileErr("Please Enter 10 digit Mobile Number");
    }
    if (errorCount === 0) {
      fetch(
        headApi + `/user/sendotp?mobile=+${mobile}&type=client&webappid=1`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          if (response.message === "Enter Registerd Mobile number") {
            setMobileErr("Please Enter Valid Mobile Number");
          }
          if (response && response.error === "false") {
            setOtpScreen({ otpScreen: true });
            setOtp(response.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleOtp = (e) => {
    setOtpNumber(e.target.value);
    setOtpNumberErr("");
  };

  const handleLogin = () => {
    let errCount = 0;
    if (otpNumber.length !== 6) {
      errCount++;
      setOtpNumberErr("Invalid OTP");
    }

    if (errCount === 0) {
      fetch(
        headApi + `/user/login?otp=${otpNumber}&mobile=+${mobile}&type=client`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          // 8141967534
          if (response.apiResponse.error !== "false") return false;
          const userObj = response.user;
          sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          setLoginModal(false);
          history.push("/product-list");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCall = (e) => {
    e.preventDefault();
    fetch(
      headApi + `/user/callMe?mobile=+${mobile}&type=client&webappid=1`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response === "Thank You for Visiting! We will reach back to you soon.") {
          alert("Thank You for Visiting! We will reach back to you soon.");
          }
      });
  };

  // useEffect(() => {
  //   ReactGA.event({
  //     category:'Home Page',
  //     action: 'Load on the landing page',
  //   })
  // }, [])

  // initializeAnalytics()



  return (
    <div>
      <Helmet>
        <title>Insha Bangles</title>
        <meta name="description" content="Best Manufacture Comapnay" />
        <meta
          name="keywords"
          content="We are one of the top manufacturers and retailers in Maharastra & Karnataka"
        />
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" textAlign="center" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "dark",
        }}
      />
      <Parallax
        image={require("assets/img/bg4.png").default}
        className={classes.parallax}
      >
        <div className={classes.container}>
          {!activeSession ? (
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Dialog
                classes={{
                  root: classPop.modalRoot,
                  paper: classPop.modal + " " + classPop.modalLogin,
                }}
                open={loginModal}
                TransitionComponent={Transition}
                keepMounted
                // onClose={() => setLoginModal(false)}
                aria-labelledby="login-modal-slide-title"
                aria-describedby="login-modal-slide-description"
              >
                <Card plain className={classPop.modalLoginCard}>
                  <DialogTitle
                    id="login-modal-slide-title"
                    disableTypography
                    className={classPop.modalHeader}
                  >
                    <CardHeader
                      plain
                      color="rose"
                      className={
                        classPop.textCenter + " " + classPop.cardLoginHeader
                      }
                    >
                      {/* <Button
                          simple
                          className={classPop.modalCloseButton}
                          key="close"
                          aria-label="Close"
                          onClick={() => {
                            setLoginModal(false);
                            history.push("/")
                          }}
                        >
                          {" "}
                          <Close className={classPop.modalClose} />
                        </Button> */}
                      <h5 className={classPop.cardTitleWhite}>Log in</h5>
                    </CardHeader>
                  </DialogTitle>
                  <DialogContent
                    id="login-modal-slide-description"
                    className={classPop.modalBody}
                  >
                    {!otpScreen ? (
                      <form>
                        <p
                          className={
                            classPop.description + " " + classPop.textCenter
                          }
                        >
                          Welcome
                        </p>
                        <CardBody className={classPop.cardLoginBody}>
                          <PhoneInput
                            inputStyle={{
                              width: "100%",
                              border: "none",
                              borderBottom: "1px solid #040000 ",
                              borderRadius: "0",
                            }}
                            country={"in"}
                            name="mobile"
                            value={mobile}
                            onChange={(mobile) => {
                              setMobile(
                                mobile.length <= 12 ? mobile : mobile
                              );
                              setMobileErr("");
                            }}
                            required={true}
                          />
                          <span style={{ color: "red" }}>{mobileErr}</span>
                        </CardBody>
                        <br />
                        <DialogActions
                          className={
                            classPop.modalFooter + " " + classPop.justifyContentCenter
                          }
                        >
                          <Button
                            color="rose"
                            simple
                            size="lg"
                            onClick={handleSendOtp}
                          >
                            Log in with OTP
                          </Button>
                        </DialogActions>
                        <DialogActions
                          className={
                            classPop.modalFooter + " " + classPop.justifyContentCenter
                          }
                        >
                          <Button
                            color="rose"
                            simple
                            size="lg"
                            href="/insha-register"
                          >
                            Create New Account
                          </Button>
                        </DialogActions>
                      </form>
                    ) : (
                      <form>
                        <p
                          className={
                            classPop.description + " " + classPop.textCenter
                          }
                        >
                          6 digit OTP SMS sent on your mobile
                        </p>
                        <p
                          className={
                            classPop.description + " " + classPop.textCenter
                          }
                        >
                          {otp}
                        </p>
                        <CardBody className={classPop.cardLoginBody}>
                          <CustomInput
                            id="otpNumber"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "otpNumber",
                              value: otpNumber,
                              onChange: (e) => handleOtp(e),
                              placeholder: "Enter OTP here",
                              type: "text",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Icon className={classes.inputIconsColor}>
                                    lock_utline
                                  </Icon>
                                </InputAdornment>
                              ),
                              autoComplete: "off",
                            }}
                            required={true}
                          />
                          <p
                            className={
                              classes.description + " " + classes.textCenter
                            }
                          >
                            {otpNumberErr}
                          </p>
                        </CardBody>
                        <div className={classes.textCenter}>
                          <Button simple color="rose" size="lg" onClick={handleLogin}>
                            Log in
                          </Button>
                          <Button simple color="rose" size="lg" onClick={handleCall}>
                            <FiPhoneCall />
                            Call Me
                          </Button>
                        </div>
                        <div className={classes.textCenter}>
                          <p className={classes.description + " " + classes.textCenter}>
                            {" "}
                            Didn{"'"}t receive the OTP yet?{" "}
                            <span style={{ marginLeft: "10px" }}>
                              <a href="#" onClick={handleSendOtp}>
                                Resend OTP
                              </a>
                            </span>
                          </p>
                        </div>
                      </form>
                    )}
                  </DialogContent>
                </Card>
              </Dialog>
            </GridItem>
          ) : (
            ""
          )}
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1>
                  INSHA BANGLES
                  {/* <span className={classes.proBadge}>PRO</span> */}
                </h1>
                <h3 className={classes.title}>
                  We have everthing in Fashion and Style, Ready for every occasion and our designs never go out of Fashion.
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <InshaSection />
        {/* <InshaTeam /> */}
      </div>
      <InshaContact />
      <Footer />
    </div>
  );
}