import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

import cardBlog4 from "assets/img/examples/card-blog4.jpg";
import office2 from "assets/img/office2.png";
import blog8 from "assets/img/examples/blog8.jpg";

const useStyles = makeStyles(descriptionStyle);

export default function ReturnDescription() {
    const classes = useStyles();
    return (
        <div className={classNames(classes.aboutDescription)}>
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.mlAuto + " " + classes.mrAuto}
                >
                    <h2 className={classes.title}>Top 5 Bangles Manufactur List</h2>
                    <br />
                    <Card plain blog className={classes.card}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <CardHeader image plain>
                                    <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                        <img src={cardBlog4} alt="cardBlog4" />
                                    </a>
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${cardBlog4})`,
                                            opacity: "1",
                                        }}
                                    />
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${cardBlog4})`,
                                            opacity: "1",
                                        }}
                                    />
                                </CardHeader>
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                                <Info>
                                    <h6 className={classes.cardCategory}>ENTERPRISE</h6>
                                </Info>
                                <h3 className={classes.cardTitle}>
                                    <a href="https://bangles.org.in" target={"_blank"} rel="noreferrer">
                                        Bangles.Org
                                    </a>
                                </h3>
                                <p className={classes.description}>

                                    A trusted name in the Bangles.Org Industry, Bangles is a reputed Manufacturer and Supplier of high quality Bangles that are extremely popular among customers due to their unique designs and patterns. We even offer customers services for labor jobs on the Bangles. We offer customers with an assorted collection of Bangles to match our customer{"'"}s taste & preferences.

                                </p>
                                {/* <p className={classes.author}>
                                    by{" "}
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <b>Mike Butcher</b>
                                    </a>{" "}
                                    , 2 days ago
                                </p> */}
                            </GridItem>
                        </GridContainer>
                        <br />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.description}>
                                    Bangles.Org is India{"'"}s leading Manufacturers {"&"} Suppliers of BANGLE{"'"}S located in Mumbai, We have been inspired and blessed to continue presenting the Best Quality, Quantity and Excellent Creation of Machine made, CNC, Fancy Bangles {"&"} Couple Bend. The Brand Bangles.Org is established by Sharif H M. At bangles.Org we strive to provide you the widespread range which includes trendy as well as traditional designs for every occasion.<br /><br />

                                    We are known as a quality provided organization because of the following points:
                                    <ul>
                                        <li>Vast industry experience</li>
                                        <li>Customer-oriented approach</li>
                                        <li>Timely delivery</li>
                                        <li>Cost-Effective Price</li>
                                        <li>Large production capacity</li>
                                        <li>Ethical policies</li>
                                    </ul>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </Card>
                    <Card plain blog className={classes.card}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <CardHeader image plain>
                                    <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                        <img src={office2} alt="office2" />
                                    </a>
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${office2})`,
                                            opacity: "1",
                                        }}
                                    />
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${office2})`,
                                            opacity: "1",
                                        }}
                                    />
                                </CardHeader>
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                                <Danger>
                                    <h6 className={classes.cardCategory}>
                                        <TrendingUp />
                                        TRENDING
                                    </h6>
                                </Danger>
                                <h3 className={classes.cardTitle}>
                                    <a href="https://omegabangles.com" target={"_blank"} rel="noreferrer">
                                        Omega Bangles
                                    </a>
                                </h3>
                                <p className={classes.description}>
                                    Make any occasion and festival great and elegant by wearing and gifting Bangles.Tradeindia brings to you a huge online collection of genuine Bangles including Ladies Bangles, Fancy Bangles, Antique Bangles in various awesome designs and sizes.

                                </p>
                                {/* <p className={classes.author}>
                                    by{" "}
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <b>Mike Butcher</b>
                                    </a>{" "}
                                    , 2 days ago
                                </p> */}
                            </GridItem>
                        </GridContainer>
                        <br />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.description}>
                                    Customer satisfaction and nurturing relationships are our topmost priority. We focus on producing high-quality products which are the reason why we are able to maintain world class standard. Reinvention is our third value which challenges us to perform better in terms of ideas and products.<br /><br />

                                    Omega Bangles, over the last 6 decades, has built a reputation for being one of the leading bangle manufacturers in India. Omega is known for creating quality products through innovative bangle designs and detailed craftsmanship. Each bangle is made on state-of-the art technology and subsequently critically examined for quality and finishing.

                                    Omega{"'"}s products are light-weight, elegant and sophisticated the perfect fit for today{"'"}s modern woman.
                                </p>
                            </GridItem>
                        </GridContainer>
                    </Card>
                    <Card plain blog className={classes.card}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <CardHeader image plain>
                                    <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                        <img src={blog8} alt="blog8" />
                                    </a>
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${blog8})`,
                                            opacity: "1",
                                        }}
                                    />
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${blog8})`,
                                            opacity: "1",
                                        }}
                                    />
                                </CardHeader>
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                                <Success>
                                    <h6 className={classes.cardCategory}>STARTUPS</h6>
                                </Success>
                                <h3 className={classes.cardTitle}>
                                    <a href="https://polabangles.com" target={"_blank"} rel="noreferrer">
                                        Pola Bangles
                                    </a>
                                </h3>
                                <p className={classes.description}>
                                    Pola has always been ahead of its time when it comes to having an esteemed customer base. In addition, it is this trust, bestowed upon us, that drivers us each day to deliver on our promise of manufacturering best in class products.

                                </p>
                                {/* <p className={classes.author}>
                                    by{" "}
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <b>Megan Rose</b>
                                    </a>{" "}
                                    , 2 days ago
                                </p> */}
                            </GridItem>
                        </GridContainer>
                        <br />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.description}>
                                    We believe Bangles have a special place in our lives since can be worn for any occasion in life and we are proud to bring our collection of Bangles to add that special touch. We strive to offer customers Bangles which are of superior quality at industry leading prices. Our mission to become a household name in the jewellery industry motivates us and we continually strive to exceed client expectations.<br /><br />
                                    We are the outstanding entity, engaged in offering utmost quality products. Besides, our assurance to meet specific customers orders within promised time has enabled us earning the trust of large clientele.<br /><br />
                                </p>
                            </GridItem>
                        </GridContainer>
                    </Card>
                    <Card plain blog className={classes.card}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <CardHeader image plain>
                                    <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                        <img src={blog8} alt="blog9" />
                                    </a>
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${blog8})`,
                                            opacity: "1",
                                        }}
                                    />
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                            backgroundImage: `url(${blog8})`,
                                            opacity: "1",
                                        }}
                                    />
                                </CardHeader>
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                                <Success>
                                    <h6 className={classes.cardCategory}>ENTERPRISE</h6>
                                </Success>
                                <h3 className={classes.cardTitle}>
                                    <a href="https://mybangles24.com" target={"_blank"} rel="noreferrer">
                                        My Bangles 24
                                    </a>
                                </h3>
                                <p className={classes.description}>
                                    My Bangles 24 has always been ahead of its time when it comes to having an esteemed customer base. In addition, it is this trust, bestowed upon us, that drivers us each day to deliver on our promise of manufacturering best in class products.
                                </p>
                                {/* <p className={classes.author}>
                                    by{" "}
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <b>Megan Rose</b>
                                    </a>{" "}
                                    , 2 days ago
                                </p> */}
                            </GridItem>
                        </GridContainer>
                        <br />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.description}>
                                    We believe Bangles have a special place in our lives since can be worn for any occasion in life and we are proud to bring our collection of Bangles to add that special touch. We strive to offer customers Bangles which are of superior quality at industry leading prices. Our mission to become a household name in the jewellery industry motivates us and we continually strive to exceed client expectations.<br /><br />
                                    We are the outstanding entity, engaged in offering utmost quality products. Besides, our assurance to meet specific customers orders within promised time has enabled us earning the trust of large clientele.<br /><br />
                                </p>
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}