import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { TiArrowForward } from "react-icons/ti";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";

import logo from "assets/img/examples/apple-insha-bangles-icon.png";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";

const useStyles = makeStyles(productStyle);

export default function OfflineInshaProductList() {
  const [prodList, setProdList] = React.useState([]);
  const [page, setpage] = React.useState(1);

  const classes = useStyles();

  const activeSession = sessionStorage.getItem("sessionData");

  const fetchData = async () => {
    setpage(page + 1);
    axios
      .get(
        headApi + `/products/limitList?userid=1&active=0&page=${page}&size=8`
      )
      .then((res) => setProdList([...prodList, ...res.data.ProductsData]));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.productPage}>
      <Helmet>
                <title>Offline Product List</title>
                <meta name="description" content="We make bangles with good quality raw material at factory prices.Bangles such as Gold Polished bangles, Die gold plated bangles, silver bangles, Micro Gold plated, rose gold plated bangles, 3tone bangles, Diamond Dell bangles, Rhodium bangles.We are basically working on customize designs and customise orders.you can get any sizes,colors, models and designs,we can make the bangles according to your requirements and needs. We are providing Zero making charges on bulk quantity orders (minimum purchase of 10 cartons)." />
                <meta name="keywords" content="fancy bangles, party wear bangles for women, stylish gold plated bangles, trendy gold bangles, imitation bangles, designer bangles, brass gold plated bangles, casual everyday bangles, elegant and beautiful bangles, finely polished, unique design angles,charming bangles, catchy designs" />
            </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
      />
      <Parallax
        image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.pageHeader}
      >
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div className={classes.relatedProducts}>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              You may also be interested in:
            </h3>
            <InfiniteScroll
              dataLength={prodList.length}
              next={fetchData}
              hasMore={true}
            >
              <div className={classes.container}>
                <GridContainer>
                  {prodList &&
                    prodList.map((prod) => (
                      <GridItem sm={6} md={3} key={prod.id}>
                        <Card product>
                          <CardHeader image>
                            <p
                              style={{
                                position: "absolute",
                                left: "90%",
                                top: "90%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <Tooltip
                                id="tooltip-tops"
                                title="Share to WhatsApp"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <a
                                  href={`https://api.whatsapp.com/send/?phone=${
                                    prod.user.whatsapp
                                  }&text=https://inshabangles.com/bangles-product?id=${
                                    prod.id
                                  }%20${
                                    prod.banglessizemm.description
                                  }%20${prod.plating.description.slice(
                                    8
                                  )}&app_absent=0`}
                                >
                                  {imgApi + "" + prod.design1.image1 !==
                                  imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : imgApi + "" + prod.design2.image2 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : imgApi + "" + prod.design3.image3 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : imgApi + "" + prod.design4.image4 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : imgApi + "" + prod.design5.image5 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : imgApi + "" + prod.design6.image6 !==
                                    imgApi + "" + "/crop_img/null" ? (
                                    <TiArrowForward size={50} color="#00acc1" />
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </Tooltip>
                            </p>

                            {prod.design1.img1active === 1 ? (
                              <img src={imgApi + "" + prod.design1.image1} alt="img1active" />
                            ) : prod.design2.img2active === 1 ? (
                              <img src={imgApi + "" + prod.design2.image2} alt="img2active" />
                            ) : prod.design3.img3active === 1 ? (
                              <img src={imgApi + "" + prod.design3.image3} alt="img3active" />
                            ) : prod.design4.img4active === 1 ? (
                              <img src={imgApi + "" + prod.design4.image4} alt="img4active" />
                            ) : prod.design5.img5active === 1 ? (
                              <img src={imgApi + "" + prod.design5.image5} alt="img5active" />
                            ) : prod.design6.img6active === 1 ? (
                              <img src={imgApi + "" + prod.design6.image6} alt="img6active" />
                            ) : (
                              ""
                            )}
                            <p
                              style={{
                                position: "absolute",
                                top: "65%",
                                left: "50%",
                                width: "65px",
                                height: "65px",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              {imgApi + "" + prod.design1.image1 !==
                              imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="logo1" />
                              ) : imgApi + "" + prod.design2.image2 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="logo2" />
                              ) : imgApi + "" + prod.design3.image3 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="logo3" />
                              ) : imgApi + "" + prod.design4.image4 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="logo4" />
                              ) : imgApi + "" + prod.design5.image5 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="logo5" />
                              ) : imgApi + "" + prod.design6.image6 !==
                                imgApi + "" + "/crop_img/null" ? (
                                <img src={logo} alt="logo6" />
                              ) : (
                                ""
                              )}
                            </p>
                          </CardHeader>
                          <h6
                            className={classNames(
                              classes.cardCategory,
                              classes.textRose
                            )}
                          >
                            {prod.plating.description.slice(8) +
                              " " +
                              prod.cat.description}{" "}
                            <br /> {prod.banglessizemm.description}{" "}
                            {prod.itemcode}-{prod.id}
                          </h6>
                        </Card>
                        <Card>
                          <CardBody>
                            <div className={classes.cardDescription}>
                              {(
                                prod.metalOption.readygram * prod.sets.pcs
                              ).toFixed(4)}{" "}
                              Avg Gram Of {prod.sets.setdesc} {"and "}
                              {prod.box.boxdesc}
                              {","} {prod.ctndescription}
                              {"."}
                            </div>
                          </CardBody>
                          <CardFooter className={classes.justifyContentBetween}>
                            <div className={classes.price}>
                              {!activeSession ? (
                                prod.taxCalculation.profitactive === 1 ? (
                                  prod.taxCalculation.cash_gst === 1 ? (
                                    <>
                                      <h5>
                                        <span style={{ color: "red" }}>
                                          Price ₹{" "}
                                          {(
                                            prod.taxCalculation.taxincl /
                                            prod.box.box
                                          ).toFixed(2)}
                                          /-
                                        </span>
                                      </h5>{" "}
                                      <h6>
                                        <a href="/bangles-login">Login</a> to
                                        see prices and exciting offers.
                                      </h6>{" "}
                                    </>
                                  ) : (
                                    <h5>
                                      Price{" "}
                                      <span>
                                        ₹{" "}
                                        {(
                                          prod.taxCalculation.cash /
                                          prod.box.box
                                        ).toFixed(2)}
                                        /-*
                                      </span>
                                    </h5>
                                  )
                                ) : prod.taxCalculation.cash_gst === 1 ? (
                                  <>
                                    <h5>
                                      <span style={{ color: "#9c27b0" }}>
                                        Price ₹{" "}
                                        {(
                                          prod.taxCalculation.taxincl /
                                          prod.box.box
                                        ).toFixed(2)}
                                        /-
                                      </span>
                                    </h5>{" "}
                                    <h6>
                                      <a href="/bangles-login">Login</a> to see
                                      prices and exciting offers.
                                    </h6>
                                  </>
                                ) : (
                                  <h5>
                                    Price{" "}
                                    <span>
                                      ₹{" "}
                                      {(
                                        prod.taxCalculation.cash / prod.box.box
                                      ).toFixed(2)}
                                      /-*
                                    </span>
                                  </h5>
                                )
                              ) : (
                                ""
                              )}
                              {activeSession ? (
                                prod.taxCalculation.profitactive === 1 ? (
                                  <h6>
                                    <span
                                      style={{
                                        textDecoration: "line-through",
                                        color: "red",
                                      }}
                                    >
                                      Price ₹
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /
                                    </span>{" "}
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </h6>
                                ) : (
                                  <h6>
                                    <span
                                      style={{
                                        textDecoration: "line-through",
                                        color: "#9c27b0",
                                      }}
                                    >
                                      Price ₹
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /
                                    </span>{" "}
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </h6>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                            <div className={classes.stats}>
                              <Tooltip
                                id="tooltip-top"
                                title="Save to Wishlist"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button justIcon color="rose" simple>
                                  <Favorite />
                                </Button>
                              </Tooltip>
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))}
                </GridContainer>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}