import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import { SiWhatsapp } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { Link } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { localApi } from "utils/headApi";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-kit-pro-react/views/sweetAlretStyle";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

const useStyles = makeStyles(contactsStyle);
const useStyl = makeStyles(sweetAlertStyle);

export default function InshaContact({ ...rest }) {
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const classes = useStyles();
  const classSweet = useStyl();

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameErr("");
  };

  const handleMobile = (e) => {
    setMobile(e.target.value);
    setMobileErr("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailErr("");
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
    setMessageErr("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter Your Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Phone Number");
    }
    if (email === "" || !email) {
      errorCount++;
      setEmailErr("Please Enter Email Address");
    }
    if (message === "" || !message) {
      errorCount++;
      setMessageErr("Please Enter Message");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: "2",
          firstName: firstName,
          mobile: mobile,
          email: email,
          websiteName: "inshabangles.com",
          message: message,
        }),
      };

      fetch(localApi + `/contactus/add`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div className="cd-section" {...rest} style={{ marginTop: "5%" }}>
      <div className={classes.contacts + " " + classes.section}>
        <div className={classes.container}>
          <GridContainer>
            {submitted ? (
              <SweetAlert
                type={alertType}
                style={{ display: "block", marginTop: "100px" }}
                title={alertMsg}
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={
                  classSweet.button + " " + classSweet.success
                }
              />
            ) : null}
            <GridItem xs={12} sm={6} md={5}>
              <h2 className={classes.title}>Contact us</h2>
              <h5 className={classes.description}>
                Feel free to ask us, If you have any Queries related to our
                Product.
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="Office Address"
                description={
                  <p>
                    Shop no.20, Ashmita Jyoti CHS D10/11 bldg, <br /> Charkop
                    Naka, Marve Road, <br /> Malad West Mumbai-400095 -
                    Maharashtra
                  </p>
                }
                icon={PinDrop}
              />
              <InfoArea
                className={classes.infoArea}
                title="Call us at"
                description={
                  <p>
                    <a href="tel:+91-2231744615">
                      <ImMobile />
                      +912231744615
                    </a>{" "}
                    <br />{" "}
                    <span>
                      <SiWhatsapp color="green" />{" "}
                      <a href="tel:+91-8360467247">+918360467247</a>
                    </span>{" "}
                    <br />
                    <Link
                      to="javascript:void(0)"
                      onClick={() =>
                        (window.location = "mailto:info@inshabangles.com")
                      }
                    >
                      <span>
                        <MdEmail /> info@inshabangles.com
                      </span>
                    </Link>
                    <br /> Monday – Saturday, <br />
                    10:00 AM to 06:00 PM <br /> Sunday - 10:00 AM to 03:00 PM
                  </p>
                }
                icon={Phone}
              />
              <a
                href={`https://api.whatsapp.com/send?phone=919967457786&text=https://inshabangles.com/%20Hello%20I%20am%20Interested%20in%20your%20Product&app_absent=0`}
              >
                <InfoArea
                  className={classes.infoArea}
                  title="Click on the Whatsapp icon to get connected with us"
                  description=""
                  icon={SiWhatsapp}
                  iconColor="success"
                />
              </a>
            </GridItem>
            <GridItem xs={12} sm={6} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader
                    contact
                    color="rose"
                    className={classes.textCenter}
                  >
                    <h4 className={classes.cardTitle}>Contact Us</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Your Name"
                      id="fristName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "fristName",
                        value: firstName,
                        onChange: (e) => handleFirstName(e),
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{firstNameErr}</span>
                    <CustomInput
                      labelText="Email address"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "email",
                        value: email,
                        onChange: (e) => handleEmail(e),
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{emailErr}</span>
                    <CustomInput
                      labelText="Phone Number"
                      id="mobile"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "mobile",
                        value: mobile,
                        onChange: (e) => handleMobile(e),
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{mobileErr}</span>
                    <CustomInput
                      labelText="Your message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 6,
                        name: "message",
                        value: message,
                        onChange: (e) => handleMessage(e),
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{messageErr}</span>
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <Button
                      color="rose"
                      className={classes.pullRight}
                      onClick={onSubmit}
                    >
                      Send Message
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
