import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
// import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
// import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { TiArrowForward } from "react-icons/ti";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import logo from "assets/img/examples/apple-insha-bangles-icon.png";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(aboutUsStyle);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ProductStock() {
  const [prodList, setProdList] = React.useState([]);
  //   const [page, setpage] = React.useState(1);
  const [loginModal, setLoginModal] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classPop = useStylPop();
  const history = useHistory();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const fetchData = async () => {
    // console.log(userObj);
    axios
      .get(
        localApi + `/product/list/byAdminId/${userObj ? userObj.admin.id : "2"}`
      )
      .then((res) => {
        const pStock = res.data.filter((f) => f.stockSize.length === 1);
        // console.log(pStock);
        setProdList(pStock);
      });
  };

  useEffect(() => {
    fetchData();
    setTimeout(hideAlertPop, 2000);
    setLoginModal(true);
  }, []);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Product Stock</title>
        <meta
          name="description"
          content="We make bangles with good quality raw material at factory prices.Bangles such as Gold Polished bangles, Die gold plated bangles, silver bangles, Micro Gold plated, rose gold plated bangles, 3tone bangles, Diamond Dell bangles, Rhodium bangles.We are basically working on customize designs and customise orders.you can get any sizes,colors, models and designs,we can make the bangles according to your requirements and needs. We are providing Zero making charges on bulk quantity orders (minimum purchase of 10 cartons)."
        />
        <meta
          name="keywords"
          content="fancy bangles, party wear bangles for women, stylish gold plated bangles, trendy gold bangles, imitation bangles, designer bangles, brass gold plated bangles, casual everyday bangles, elegant and beautiful bangles, finely polished, unique design angles,charming bangles, catchy designs"
        />
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
      />
      <Parallax
        // image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.pageHeader}
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>Product Stock</h1>
              <h4 style={{ color: "white" }}>
                Here you will get to see our elegant collection of different
                designs, sizes and colors of bangles. These are our overall
                products which you can buy online as well as offline.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <Dialog
            style={{ marginTop: "10%" }}
            classes={{
              root: classPop.modalRoot,
              paper: classPop.modal + " " + classPop.modalLogin,
            }}
            open={loginModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setLoginModal(false)}
            aria-labelledby="login-modal-slide-title"
            aria-describedby="login-modal-slide-description"
          >
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <h5>Loading...</h5>
              <DialogContent
                id="login-modal-slide-description"
                className={classPop.modalBody}
              >
                <GridContainer justify="center">
                  <Spinner
                    onChange={() => hideAlertPop()}
                    style={{ color: "black" }}
                  />
                  {/* <Spinner onChange={() => hideAlertPop()} style={{ color: "#00acc1" }} />
                    <Spinner onChange={() => hideAlertPop()} style={{ color: "#f44336" }} />
                    <Spinner onChange={() => hideAlertPop()} style={{ color: "#ff9800" }} />
                    <Spinner onChange={() => hideAlertPop()} style={{ color: "#9c27b0" }} /> */}
                </GridContainer>
                <br />
              </DialogContent>
            </DialogTitle>
          </Dialog>
          <div className={classes.relatedProducts}>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              You may also be interested in:
            </h3>
            {/* <InfiniteScroll
              dataLength={prodList.length}
              next={fetchData}
              hasMore={true}
            > */}
            <div className={classes.container}>
              <GridContainer>
                {prodList &&
                  prodList.map((prod) => (
                    <GridItem sm={6} md={3} key={prod.id}>
                      <Card product>
                        <CardHeader image>
                          {/* <CornerRibbon
                                                        position={prod.total > 0 ? "top-left" : "top-right"} // position="top-right" // OPTIONAL, default as "top-right"
                                                        fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                                                        backgroundColor="#00acc1" // OPTIONAL, default as "#2c7"
                                                        containerStyle={{}} // OPTIONAL, style of the ribbon
                                                        style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                                                        className="" // OPTIONAL, css class of ribbon
                                                    > {prod.total > 0 ? "In Stock" : "Make To Order"}
                                                    </CornerRibbon> */}
                          <p
                            style={{
                              position: "absolute",
                              left: "90%",
                              top: "90%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <Tooltip
                              id="tooltip-tops"
                              title="Share to WhatsApp"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <a
                                href={`https://api.whatsapp.com/send/?phone=919967457786&text=https://inshabangles.com/insha-product?id=${prod.id}%20${prod.productCategory.title}%20${prod.productParent.title}&app_absent=0`}
                              >
                                {prod.productImage[0].cropImageUrl +
                                "?id=" +
                                prod.productImage[0].imgId ? (
                                  <TiArrowForward size={50} color="#00acc1" />
                                ) : (
                                  ""
                                )}
                                {/* {imgApi + "" + prod.design1.image1 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design2.image2 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design3.image3 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design4.image4 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design5.image5 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design6.image6 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : (
                                                                    ""
                                                                )} */}
                              </a>
                            </Tooltip>
                          </p>

                          <img
                            src={
                              prod.productImage[0].cropImageUrl +
                              "?id=" +
                              prod.productImage[0].imgId
                            }
                            alt="..."
                          />
                          <p
                            style={{
                              position: "absolute",
                              top: "65%",
                              left: "50%",
                              width: "65px",
                              height: "65px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </CardHeader>
                        <h6
                          className={classNames(
                            classes.cardCategory,
                            classes.textRose
                          )}
                        >
                          {prod.productCategory.title} <br />{" "}
                          {prod.productParent.title} {prod.itemcode}-{prod.id}
                        </h6>
                      </Card>
                      <Card>
                        <CardBody>
                          <div className={classes.cardDescription}>
                            {(prod.readyGram * prod.productSets.pcs).toFixed(4)}{" "}
                            Avg Gram Of {prod.productSets.setDesc} {"and "}
                            {prod.productBox.boxDesc}
                            {","} {prod.cartDescription}
                            {"."}
                          </div>
                          <GridContainer className={classes.sizePadding}>
                            {prod.stockSize[0].s1x8 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x8 == null
                                  ? ""
                                  : prod.stockSize[0].s1x8 == 0
                                  ? ""
                                  : "1x8"}
                                <br />
                                {prod.stockSize[0].s1x8 == null
                                  ? ""
                                  : prod.stockSize[0].s1x8 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x10 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x10 == null
                                  ? ""
                                  : prod.stockSize[0].s1x10 == 0
                                  ? ""
                                  : "1x10"}
                                <br />
                                {prod.stockSize[0].s1x10 == null
                                  ? ""
                                  : prod.stockSize[0].s1x10 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x12 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x12 == null
                                  ? ""
                                  : prod.stockSize[0].s1x12 == 0
                                  ? ""
                                  : "1x12"}
                                <br />
                                {prod.stockSize[0].s1x12 == null
                                  ? ""
                                  : prod.stockSize[0].s1x12 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x14 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x14 == null
                                  ? ""
                                  : prod.stockSize[0].s1x14 == 0
                                  ? ""
                                  : "1x14"}
                                <br />
                                {prod.stockSize[0].s1x14 == null
                                  ? ""
                                  : prod.stockSize[0].s1x14 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x0 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x0 == null
                                  ? ""
                                  : prod.stockSize[0].s2x0 == 0
                                  ? ""
                                  : "2x0"}
                                <br />
                                {prod.stockSize[0].s2x0 == null
                                  ? ""
                                  : prod.stockSize[0].s2x0 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x0}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x2 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x2 == null
                                  ? ""
                                  : prod.stockSize[0].s2x2 == 0
                                  ? ""
                                  : "2x2"}
                                <br />
                                {prod.stockSize[0].s2x2 == null
                                  ? ""
                                  : prod.stockSize[0].s2x2 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x2}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x4 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x4 == null
                                  ? ""
                                  : prod.stockSize[0].s2x4 == 0
                                  ? ""
                                  : "2x4"}
                                <br />
                                {prod.stockSize[0].s2x4 == null
                                  ? ""
                                  : prod.stockSize[0].s2x4 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x4}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x6 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x6 == null
                                  ? ""
                                  : prod.stockSize[0].s2x6 == 0
                                  ? ""
                                  : "2x6"}
                                <br />
                                {prod.stockSize[0].s2x6 == null
                                  ? ""
                                  : prod.stockSize[0].s2x6 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x6}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x8 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x8 == null
                                  ? ""
                                  : prod.stockSize[0].s2x8 == 0
                                  ? ""
                                  : "2x8"}
                                <br />
                                {prod.stockSize[0].s2x8 == null
                                  ? ""
                                  : prod.stockSize[0].s2x8 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x10 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x10 == null
                                  ? ""
                                  : prod.stockSize[0].s2x10 == 0
                                  ? ""
                                  : "2x10"}
                                <br />
                                {prod.stockSize[0].s2x10 == null
                                  ? ""
                                  : prod.stockSize[0].s2x10 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x12 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x12 == null
                                  ? ""
                                  : prod.stockSize[0].s2x12 == 0
                                  ? ""
                                  : "2x12"}
                                <br />
                                {prod.stockSize[0].s2x12 == null
                                  ? ""
                                  : prod.stockSize[0].s2x12 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x14 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x14 == null
                                  ? ""
                                  : prod.stockSize[0].s2x14 == 0
                                  ? ""
                                  : "2x14"}
                                <br />
                                {prod.stockSize[0].s2x14 == null
                                  ? ""
                                  : prod.stockSize[0].s2x14 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s3x0 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s3x0 == null
                                  ? ""
                                  : prod.stockSize[0].s3x0 == 0
                                  ? ""
                                  : "3x0"}
                                <br />
                                {prod.stockSize[0].s3x0 == null
                                  ? ""
                                  : prod.stockSize[0].s3x0 == 0
                                  ? ""
                                  : prod.stockSize[0].s3x0}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          <GridContainer className={classes.sizePadding}>
                            {prod.stockSize[0].s1x9 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x9 == null
                                  ? ""
                                  : prod.stockSize[0].s1x9 == 0
                                  ? ""
                                  : "1x9"}
                                <br />
                                {prod.stockSize[0].s1x9 == null
                                  ? ""
                                  : prod.stockSize[0].s1x9 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x11 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x11 == null
                                  ? ""
                                  : prod.stockSize[0].s1x11 == 0
                                  ? ""
                                  : "1x11"}
                                <br />
                                {prod.stockSize[0].s1x11 == null
                                  ? ""
                                  : prod.stockSize[0].s1x11 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x13 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x13 == null
                                  ? ""
                                  : prod.stockSize[0].s1x13 == 0
                                  ? ""
                                  : "1x13"}
                                <br />
                                {prod.stockSize[0].s1x13 == null
                                  ? ""
                                  : prod.stockSize[0].s1x13 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x15 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x15 == null
                                  ? ""
                                  : prod.stockSize[0].s1x15 == 0
                                  ? ""
                                  : "1x15"}
                                <br />
                                {prod.stockSize[0].s1x15 == null
                                  ? ""
                                  : prod.stockSize[0].s1x15 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x1 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x1 == null
                                  ? ""
                                  : prod.stockSize[0].s2x1 == 0
                                  ? ""
                                  : "2x1"}
                                <br />
                                {prod.stockSize[0].s2x1 == null
                                  ? ""
                                  : prod.stockSize[0].s2x1 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x1}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x3 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x3 == null
                                  ? ""
                                  : prod.stockSize[0].s2x3 == 0
                                  ? ""
                                  : "2x3"}
                                <br />
                                {prod.stockSize[0].s2x3 == null
                                  ? ""
                                  : prod.stockSize[0].s2x3 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x3}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x5 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x5 == null
                                  ? ""
                                  : prod.stockSize[0].s2x5 == 0
                                  ? ""
                                  : "2x5"}
                                <br />
                                {prod.stockSize[0].s2x5 == null
                                  ? ""
                                  : prod.stockSize[0].s2x5 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x5}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x7 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x7 == null
                                  ? ""
                                  : prod.stockSize[0].s2x7 == 0
                                  ? ""
                                  : "2x7"}
                                <br />
                                {prod.stockSize[0].s2x7 == null
                                  ? ""
                                  : prod.stockSize[0].s2x7 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x7}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x9 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x9 == null
                                  ? ""
                                  : prod.stockSize[0].s2x9 == 0
                                  ? ""
                                  : "2x9"}
                                <br />
                                {prod.stockSize[0].s2x9 == null
                                  ? ""
                                  : prod.stockSize[0].s2x9 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x11 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x11 == null
                                  ? ""
                                  : prod.stockSize[0].s2x11 == 0
                                  ? ""
                                  : "2x11"}
                                <br />
                                {prod.stockSize[0].s2x11 == null
                                  ? ""
                                  : prod.stockSize[0].s2x11 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x13 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x13 == null
                                  ? ""
                                  : prod.stockSize[0].s2x13 == 0
                                  ? ""
                                  : "2x13"}
                                <br />
                                {prod.stockSize[0].s2x13 == null
                                  ? ""
                                  : prod.stockSize[0].s2x13 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x15 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x15 == null
                                  ? ""
                                  : prod.stockSize[0].s2x15 == 0
                                  ? ""
                                  : "2x15"}
                                <br />
                                {prod.stockSize[0].s2x15 == null
                                  ? ""
                                  : prod.stockSize[0].s2x15 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s3x1 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s3x1 == null
                                  ? ""
                                  : prod.stockSize[0].s3x1 == 0
                                  ? ""
                                  : "3x1"}
                                <br />
                                {prod.stockSize[0].s3x1 == null
                                  ? ""
                                  : prod.stockSize[0].s3x1 == 0
                                  ? ""
                                  : prod.stockSize[0].s3x1}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                        </CardBody>
                        <CardFooter className={classes.justifyContentBetween}>
                          <div className={classes.price}>
                            {prod.gstCash === true ? (
                              <>
                                <h5>
                                  <span style={{ color: "red" }}>
                                    Price ₹{" "}
                                    {prod.taxInclude.toFixed(2) + " Inc."}
                                  </span>
                                </h5>
                              </>
                            ) : (
                              <>
                                <h5>
                                  <span style={{ color: "black" }}>
                                    Price ₹ {prod.cashPrice.toFixed(2)}
                                  </span>
                                </h5>
                              </>
                            )}
                            {/* {!activeSession ? <h5 style={{ color: "black" }}>
                                                            Price{" "}
                                                            <span>
                                                                ₹{" "}
                                                                {(
                                                                    prod.taxCalculation.cash / prod.box.box
                                                                ).toFixed(2)}
                                                                /-*
                                                            </span>
                                                        </h5> : <h5 style={{ color: "black" }}>
                                                            Price{" "}
                                                            <span>
                                                                ₹{" "}
                                                                {(
                                                                    prod.taxCalculation.cash / prod.box.box
                                                                ).toFixed(2)}
                                                                /-*
                                                            </span>
                                                        </h5>} */}
                          </div>
                          {/* <div className={classes.stats}>
                                                        <Tooltip
                                                            id="tooltip-top"
                                                            title="Save to Wishlist"
                                                            placement="top"
                                                            classes={{ tooltip: classes.tooltip }}
                                                        >
                                                            <Button justIcon color="rose" simple>
                                                                <Favorite />
                                                            </Button>
                                                        </Tooltip>
                                                    </div> */}
                                                    
                        </CardFooter>
                        <div style={{textAlign : "center", marginBottom: "7px"}}>
                            <Button
                              color="rose"
                              href={`/insha-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                              // &Plating=${prod.plating.description.slice(8)}
                            >
                              {/* <Subject /> */}
                              Buy Now
                            </Button>
                          </div>
                        
                        {/* <CardFooter className={classes.justifyContentBetween}>
                          <div className={classes.price}>
                            <Button
                              color="rose"
                              href={`/insha-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                              
                            >
                              Buy Now
                            </Button>
                          </div>
                          <div>
                            <Button
                            color="success"
                            round
                            justIcon
                            href={`https://api.whatsapp.com/send/?phone=${
                              prod.user.whatsapp
                            }&text=https://bangles.org.in/bangles-product?id=${
                              prod.id
                            }%20${
                              prod.parent.title
                            }%20${prod.plating.description.slice(
                              8
                            )}&app_absent=0`}
                          >
                            <i
                              className="fab fa-whatsapp"
                              style={{ fontSize: "30px" }}
                            />
                          </Button>
                          </div>
                        </CardFooter> */}
                      </Card>
                    </GridItem>
                  ))}
              </GridContainer>
            </div>
            {/* </InfiniteScroll> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
