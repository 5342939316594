/* eslint-disable */
import React, { useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { FaUserEdit } from "react-icons/fa";
import { localApi } from "utils/headApi";
import SweetAlert from "react-bootstrap-sweetalert";

import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";
import { Redirect } from "react-router-dom";
import { getStates, getCities } from "utils/util";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import dropDown from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(profilePageStyle);
const useStyleAlert = makeStyles(style);
const useStyl = makeStyles(aboutUsStyle);
const useStyle = makeStyles(dropDown);

export default function EditProfile({ ...rest }) {
  const [countryList, setCountryList] = React.useState([]);
  const [firstname, setFristname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [whatsapp, setWhatsapp] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [city, setCity] = React.useState("");
  const [cityErr, setCityErr] = React.useState("");
  const [stateNameErr, setStateNameErr] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");

  const [stateSelect, setStateSelect] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  // });

  const classes = useStyles();
  const classeAlert = useStyleAlert();
  const classeTitel = useStyl();
  const classe = useStyle();

  const activeSession = sessionStorage.getItem("sessionData");
  let userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function fetchCountryListCode() {
    const api = localApi + `/countrylistcode/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    setCountryList(getResult && getResult);
  }

  useEffect(() => {
    fetchCountryListCode();
  }, []);

  const handleMobileNumber = (whatsapp) => {
    setWhatsapp(whatsapp);
  };

  const handleCompany = (e) => {
    setCompany(e.target.value);
  }

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateNameErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCityErr("");
  };

  const handleCountry = (event) => {
    setCountry(event.target.value);
  }

  React.useEffect(() => {
    axios
      .get(
        localApi + `/register/find/byId/${userObj && userObj.id}`
      )
      .then((res) => {
        // console.log(res);s
        setFristname(res.data.firstname);
        setLastname(res.data.lastname);
        setWhatsapp(res.data.whatsapp);
        setCompany(res.data.company);
        setActive(res.data.smsMarketing);
        setCity(res.data.city);
        // setCitySelect(res.data.city);
        setState(res.data.state);
        // setStateSelect(res.data.state);
        setCountry(res.data.country);
        setCountryCode(res.data.countryCode);
        // setCountryCode("in");
      });
  }, []);



  const SubmitProfile = () => {

    let errorCount = 0;
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateNameErr("State is Required");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCityErr("City is Required");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          firstname: firstname,
          lastname: lastname,
          whatsapp: whatsapp,
          company: company,
          city: citySelect,
          state: stateSelect,
          country: country
        }),
      };

      fetch(
        localApi + `/register/update/byId/${userObj.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            setSubmitted(true);
            alertTypeOps("success");
            alertMsgOps("Updated Successfully");
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div>
      {activeSession ? <>
        <Helmet>
          <title>Edit Profile</title>
        </Helmet>
        <InshaHeader
          color="transparent"
          brand="Home"
          links={<InshaHeaderLink dropdownHoverColor="dark" />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "dark",
          }}
          {...rest}
        />
        <Parallax
          image={require("assets/img/examples/city.png").default}
          filter="dark"
          className={classes.parallax}
        >
          <div className={classeTitel.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classeTitel.mlAuto,
                  classeTitel.mrAuto,
                  classeTitel.textCenter
                )}
              >
                <h1 className={classeTitel.title}>PROFILE</h1>
                <h4>

                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {submitted ? (
              <SweetAlert
                type={alertType}
                style={{ display: "block", marginTop: "100px" }}
                title={alertMsg}
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={
                  classeAlert.button + " " + classeAlert.success
                }
              />
            ) : null}
            <div className={classes.profileTabs}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <h3 className={classes.title}>
                      {" "}
                      <FaUserEdit color="#e91e63" size={35} /> Edit Profile
                    </h3>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="First Name"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      placeholder: "First Name...",
                      onChange: (e) => setFristname(e.target.value),
                      name: "firstname",
                      value: firstname,
                    }}
                    id="firstName"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Last Name"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      placeholder: "Last Name...",
                      onChange: (e) => setLastname(e.target.value),
                      name: "lastname",
                      value: lastname,
                    }}
                    id="lastName"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <label>Enter Mobile Number</label>
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "100%",
                      border: "none",
                      borderBottom: "1px solid #040000 ",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="whatsapp"
                    value={whatsapp}
                    onChange={(whatsapp) => {
                      let country_code = document.body.querySelector('div.flag')?.className
                      country_code = country_code && country_code.replace("flag", '')
                      setCountryCode(country_code.trim());
                      handleMobileNumber(whatsapp);
                      let country_name = document.body.querySelector('div.selected-flag')?.title
                      setCountryName(country_name.slice(0, -6));
                    }}
                    required={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    id="company"
                    inputProps={{
                      onChange: (e) => handleCompany(e),
                      value: company,
                      name: "company",
                      placeholder: "Enter your Company Name...",
                    }}
                    required={true}
                    labelText="Company "
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select State ... {state}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={stateSelect}
                      onChange={handleState}
                      required={true}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select State
                      </MenuItem>
                      {getStates(countryCode).map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          onClick={() => 
                            setStateCode(item?.state_code)}
                          classes={{
                            root: classe.selectMenuItem,
                            selected: classe.selectMenuItemSelected,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{stateNameErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select City ... {city}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={citySelect}
                      onChange={handleCity}
                      required={true}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select City
                      </MenuItem>
                      {getCities(countryCode, stateCode).map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          onClick={() => setStateCode(item?.state_code)}
                          classes={{
                            root: classe.selectMenuItem,
                            selected: classe.selectMenuItemSelected,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{cityErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    id="country"
                    inputProps={{
                      onChange: (e) => handleCountry(e),
                      value: country,
                      name: "country",
                      placeholder: "Enter your Country Name...",
                    }}
                    required={true}
                    labelText="Country"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}></GridItem>
              </GridContainer>
              <br />
              <GridContainer justify="center">
                <Button color="rose" round onClick={SubmitProfile}>
                  Save
                </Button>
              </GridContainer>
            </div>
            <br />
          </div>
        </div>
        <br />
        <br />
        <Footer />
      </> : <Redirect to="/" />}
    </div>
  );
}