import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// import Muted from "components/Typography/Muted.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import office2 from "assets/img/examples/office2.png";
import cardBlog3 from "assets/img/examples/card-blog3.png";
import cardProject1 from "assets/img/examples/card-project1.png";
import cardProject2 from "assets/img/examples/card-project2.png";
import cardProject3 from "assets/img/examples/card-project3.png";
import cardProject4 from "assets/img/examples/card-project4.png";
import cardProject6 from "assets/img/examples/card-project6.png";

const useStyles = makeStyles(projectsStyle);

export default function InshaSectionPro({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            <div className={classes.projects}>
                <div className={classes.container}>
                    {/* <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                            }
                        >
                            <h3 className={classes.title}>
                                A jewel from us means jewelry forever, once you try our jewelery you will solve the mystery.
                            </h3>
                            <NavPills
                                alignCenter
                                color="rose"
                                tabs={[
                                    { tabButton: "All", tabContent: "once you try our jewelery" },
                                    { tabButton: "Marketing", tabContent: "you will solve the mystery" },
                                    { tabButton: "Development", tabContent: "We make jewelry with the best" },
                                    { tabButton: "Productivity", tabContent: "Just as love is priceless" },
                                    { tabButton: "Web Design", tabContent: "once you try our jewelery We make jewelry with the best" },
                                ]}
                            />
                            <div className={classes.tabSpace} />
                        </GridItem>
                    </GridContainer> */}
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card
                                raised
                                background
                                style={{ backgroundImage: `url(${office2})`, backgroundColor: "transparent" }}
                            >
                                <CardBody background>
                                    {/* <h6 className={classes.cardCategory}>PRODUCTIVITY</h6> */}
                                    <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                        <h3 className={classes.cardTitleWhite}>
                                            The Joy of Wearing Best
                                        </h3>
                                    </a>
                                    <p className={classes.cardDescription}>
                                     We make jewelry with the best standard Fashion and Style is everything we have.
                                    </p>
                                    <Button round color="rose" href={`/insha-product?id=374&catdesc=Bangles&cat1desc=6MM&Plating=3tone`}>
                                        <Icon>content_copy</Icon> View App
                                    </Button>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card
                                raised
                                background
                                style={{ backgroundImage: `url(${cardBlog3})` }}
                            >
                                <CardBody background>
                                    {/* <h6 className={classes.cardCategory}>DESIGN</h6> */}
                                    <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                        <h3 className={classes.cardTitleWhite}>
                                        Priceless Love
                                        </h3>
                                    </a>
                                    <p className={classes.cardDescription}>
                                    Just as love is priceless, Jewelry is also precious.
                                    </p>
                                    <Button round color="rose" href={`/insha-product?id=262&catdesc=Bangles&cat1desc=7MM&Plating=Gold%20Plated`}>
                                        <Build />
                                        View Project
                                    </Button>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card
                                raised
                                background
                                style={{ backgroundImage: `url(${cardProject6})` }}
                            >
                                <CardBody background>
                                    {/* <h6 className={classes.cardCategory}>MARKETING</h6> */}
                                    <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                        <h3 className={classes.cardTitleWhite}>
                                        Live every Moment
                                        </h3>
                                    </a>
                                    <p className={classes.cardDescription}>
                                    Take a moment and cherish the collection, let{"'"}s check them out because there is no harm in looking.
                                    </p>
                                    <Button round color="rose" href={`/insha-product?id=495&catdesc=Punjabi%20kada&cat1desc=14MM&Plating=Bright%20Silver`}>
                                        <Subject /> Case study
                                    </Button>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            <div
                className={
                    classes.projects + " " + classes.sectionDark + " " + classes.projects3
                }
            >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                            }
                        >
                            {/* <Muted>
                                <h6>OUR WORK</h6>
                            </Muted> */}
                            <h2 className={classes.title}>
                                Some of Our Awesome Products
                            </h2>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                            <Card plain className={classes.card2}>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <CardHeader plain image>
                                        <img src={cardProject1} alt="Bangles-8MM-3-tone" />
                                    </CardHeader>
                                </a>
                                <CardBody>
                                    {/* <h6 className={classes.cardCategory}>WEB DESIGN</h6> */}
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <h4 className={classes.cardTitle}>
                                            Come if you want something Better
                                        </h4>
                                    </a>
                                    <p className={classes.cardDescription}>
                                    Serving something extra.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                            <Card plain className={classes.card2}>
                                <a href={`/insha-product?id=413&catdesc=Bangles&cat1desc=8MM&Plating=Gold%20Plated`}>
                                    <CardHeader plain image>
                                        <img src={cardProject2} alt="Bangles-8MM-Gold-Plated" />
                                    </CardHeader>
                                </a>
                                <CardBody>
                                    {/* <h6 className={classes.cardCategory}>PRODUCTIVITY TOOLS</h6> */}
                                    <a href={`/insha-product?id=413&catdesc=Bangles&cat1desc=8MM&Plating=Gold%20Plated`}>
                                        <h4 className={classes.cardTitle}>
                                            We offer an exclusive collection
                                        </h4>
                                    </a>
                                    <p className={classes.cardDescription}>
                                        Exact Design as you wanted.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                            <Card plain className={classes.card2}>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <CardHeader plain image>
                                        <img src={cardProject3} alt="Bangles-list" />
                                    </CardHeader>
                                </a>
                                <CardBody>
                                    {/* <h6 className={classes.cardCategory}>ANDROID APP</h6> */}
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <h4 className={classes.cardTitle}>Bring spark to everyday Life</h4>
                                    </a>
                                    <p className={classes.cardDescription}>
                                        Jewellery Designs that are always Stylish.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                            <Card plain className={classes.card2}>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <CardHeader plain image>
                                        <img src={cardProject4} alt="Bangles-10MM-Gold-Plated" />
                                    </CardHeader>
                                </a>
                                <CardBody>
                                    {/* <h6 className={classes.cardCategory}>WEBSITE</h6> */}
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <h4 className={classes.cardTitle}>Purity is a Purpose</h4>
                                    </a>
                                    <p className={classes.cardDescription}>
                                        You will never forget what you see our Work.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>   
                </div>
            </div>
        </div>
    );
}